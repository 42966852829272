












































































































































































































































































import {Component, Mixins, Watch} from 'vue-property-decorator'
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import {
  CheckPromotionCode,
  CreateSubscription,
  UpdateSubscription
} from "@/graphql/WorkspaceSubscription";
import {WorkspaceQuery} from "@/graphql/workspace";
import CountryFlag from 'vue-country-flag'
import EditBillingDetails from "@/components/subscription/EditBillingDetails.vue";
import PaymentMethods from "@/components/subscription/PaymentMethods.vue";
import * as ct from "countries-and-timezones";
import WorkspacePage from "@/components/layout/WorkspacePage.vue";
import {PromotionCode} from "@/typescript/types";
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import Template from "@/components/templates/Template.vue";
import NotificationBarCountDownTimer from '@/components/general/NotificationBarCountDownTimer.vue';
import PlansBenefitsList from "@/components/subscription/PlansBenefitsList.vue"


@Component({
  components: {Template, WorkspacePage, StripeElementCard, CountryFlag, EditBillingDetails, PaymentMethods, NotificationBarCountDownTimer, PlansBenefitsList}
})
export default class Checkout extends Mixins(ValidationErrorParserMixin) {

  totalUsers: number = 1
  stripe: any | null = null;
  token: string | null = null
  cardNumber: any | null = null
  cardExpiry: any | null = null
  cardCvc: any | null = null
  name: string | null = null
  name_error: string | null = null
  email: string | null = null
  activeTab: string = 'saved-card'
  savedCards: Array<any> = [];
  payment_method: string | null = null;
  loading: boolean = false;
  intervals: any = {'month': 'Monthly', 'year': 'Yearly', 'day': 'Daily', 'week': 'Weekly'}
  showDetails: boolean = false;
  showFullList: boolean = false;

  selectedInterval: String = 'month'
  billingDetails: Array<any> = []


  promotionCode: string | null = null;
  promotionCode_error: string | null = null;
  couponCodeId: string | null = null;

  netAmount: number = 0;
  discountAmt: number = 0;
  appliedCoupon: boolean = false;
  discountAmtOrPercent: string | null = null;
  couponButtonLoading: boolean = false;
  couponData: PromotionCode | null = null;

  detailsConfirmed: boolean = false;
  currentlyLoading: boolean = false;

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get userEmail() {
    return this.$store.state.me.email;
  }

  get userName() {
    return this.$store.state.me.first_name + ' ' + this.$store.state.me.last_name;
  }

  get userCountry() {
    return this.$store.state.me.country
     
  }

  get isDisabled() {
    if(this.me.billingDetails.length > 0 && this.payment_method) {
      return false;
    }
    return true;
  }

  get showOffer() {
    return this.$store.state.initial_offer_code;
  }

  get haveBillingDetails() {
    if(this.me.billingDetails.length > 0) {
      return true;

    }
    return false;
  }

  get filterDeletedWorkspaceMember() {
    if(this.workspace.members) {
      return this.workspace.members.filter((member: any) => {
        return member.user.deleted_at === null;
      })
    }
    return null;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get plans() {
    return this.$store.state.plans.filter((plan: any) => plan.stripeProduct.id == this.$route.params.productId );
  }

  get stripeElements () {
    //@ts-ignore
    return this.$stripe.elements();
  }

  get couponCode() {
    return this.$store.state.coupon_code;
  }

  get selectedPlan() {
    return this.plans.filter((plan: any) => plan.interval == this.selectedInterval)[0]
  }

  get savingsAmount(): number {
    if (this.selectedPlan) {
      const monthlyTotal = this.selectedPlan.price * this.totalUsers * 12;
      const yearlyTotal = this.selectedPlan.monthly_base_price * this.totalUsers * 12;
      const saving = monthlyTotal - yearlyTotal - this.discountAmt;
      return saving > 0 ? parseFloat(saving.toFixed(2)) : 0;
    }
    return 0;
  }

  get subscriptionPrice() {
    if(this.selectedPlan) {
      
      let price = 0;
      if(this.selectedInterval == 'year') {
        price = (this.selectedPlan.monthly_base_price * 12) * this.totalUsers;
        this.netAmount = parseFloat(((this.selectedPlan.price * this.totalUsers) - this.discountAmt).toFixed(1));
        
      }else{
        price = this.selectedPlan.price * this.totalUsers;
        this.netAmount = parseFloat((price - this.discountAmt).toFixed(1));
      }

      return price;
    }
    return 0;

  }

  get ccLoading() {
    return this.$store.state.cc_loading;
  }

  get currentPlan() {
    if(this.workspace.currentSubscription !== null) {
      return this.workspace.currentSubscription.plan.title
    }
    else { return null }
  }

  handleBackClick() {
    this.$router.push({ name: 'dashboard-layout' })
  }


  startLoading() {
    this.currentlyLoading = true;
    // fallback if billingDetails doesn't send stop signal
    setTimeout(() => this.currentlyLoading = false, 12000);
  }
  stopLoading() {
    this.currentlyLoading = false;
  }


  created() {
    this.totalUsers = this.filterDeletedWorkspaceMember.length;

    if(this.selectedPlan) {
      this.netAmount = parseFloat((this.subscriptionPrice - this.discountAmt).toFixed(1));
    }
    
    this.promotionCode = this.couponCode;
    if(this.promotionCode) {
      this.checkPromotionCode();
    }

  
  }

  startNewSubscription() {
    this.currentlyLoading = true;
    this.handelSubmit();
  }

  handleShowDetails() {
    this.showDetails = !this.showDetails
  }

  mounted() {

    const { billingPeriod } = this.$route.params;
  

    if(billingPeriod === "monthly") {
      this.selectedInterval = "month";
    } else if(billingPeriod === "yearly") {
      this.selectedInterval = "year";
    }
    

    this.$store.commit('set_checkout_step', 2);
    this.$gtag.event('pmb_app_final_checkout_mounted');
    this.$events.listen('defaultPaymentMethod', (eventData) => {
     
      this.payment_method = eventData;
   
      if(!this.currentPlan) {
        this.handelSubmit();
      }
      
    })

    if(this.haveBillingDetails) {
      this.$emit('next-step', true);
      this.$store.commit('set_cc_loading', false);
    }
  }


  getTaxName(tax_type: string) {
    let taxType = tax_type.split('-');
    const taxname = taxType[0].replace(/_/g, " ");
    let country = this.getCountry(taxType[1]);
    return taxname.toUpperCase() + ' ' + country;
  }

  getCountry(countryCode: string) {
    const country = ct.getCountry(countryCode);
    if(country) {
      return country.name;
    }

  }

  handelSubmit() {
    this.loading = true;
    if(this.workspace.currentSubscription && this.workspace.currentSubscription.hasAccess) {
      
      this.updateSubscription();
    }else{
      this.$gtag.event('pmb_app_final_checkout_subscription_start_attempt');
      this.createSubscription();
    }
  }

  createSubscription() {
    console.log("payment: " + this.payment_method)
    this.$apollo.mutate({
      mutation: CreateSubscription,
      variables: {
        workspace_id: this.workspace.id,
        paymentMethod: this.payment_method,
        stripe_plan_id: this.selectedPlan.stripe_plan_id,
        itemQuantity: this.totalUsers,
        coupon: this.couponCodeId
      },
      refetchQueries:[{query: WorkspaceQuery, variables: {pid: this.workspace.pid}}]
    }).then((res: any) => {
      if(res.data.makeSubscription.subscription_id) {
        this.$router.push({name: 'subscription-success-page', params: {workspace_pid: this.$store.state.workspace.pid}});
        this.$store.commit('set_show_initial_offer_notification_bar', false)
        this.$gtag.event('pmb_app_final_checkout_subscription_start_success');
        this.loading = false;
        this.$buefy.toast.open({
          message: 'Subscription successful',
          position: 'is-bottom-right',
          type: 'is-black',
        });

        setTimeout(() => this.currentlyLoading = false, 2000);
      
      }
    }).catch((error: any) => {
      this.parseValidationError(error);
      this.$gtag.event('pmb_app_final_checkout_subscription_start_failed', {error: error.message});
      this.loading = false;
    })
  
  }

  updateSubscription() {
    this.$apollo.mutate({
      mutation: UpdateSubscription,
      variables: {
        subscription_id: this.workspace.currentSubscription.subscription_id,
        stripe_plan_id: this.selectedPlan.stripe_plan_id,
        itemQuantity: this.totalUsers,
        prorationBehavior: 'always_invoice',
        payment_method: this.payment_method
      },
    }).then((res: any) => {
      if(res.data.updateSubscription.subscription_id) {
        this.loading = false;
        this.$gtag.event('pmb_app_final_checkout_subscription_update_success');
        this.$router.push({name: 'subscription-success-page', params: {workspace_pid: this.$store.state.workspace.pid}});
        this.$buefy.toast.open({
          message: 'Subscription successfull',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        this.$store.commit('set_show_initial_offer_notification_bar', false)
        this.$store.commit('set_initial_offer_code', null);
        this.$store.commit('set_show_initial_offer', null)
        this.$gtag.event('pmb_initial_offer_claimed' + this.couponData);
      }
      setTimeout(() => this.currentlyLoading = false, 2000);
    }).catch((error: any) => {
      this.parseValidationError(error);
      this.$gtag.event('pmb_app_final_checkout_subscription_update_failed', {error: error.message});
      this.loading = false;
    })
    
  }

  checkPromotionCode() {
    this.couponButtonLoading = true;
    this.$gtag.event('pmb_app_final_checkout_check_promo_code');
    this.$apollo.mutate({
      mutation: CheckPromotionCode,
      variables: {
        code: this.promotionCode
      }
    }).then((res: any) => {
      this.couponButtonLoading = false;
      this.couponData = res.data.checkPromotionCode;
      if(res.data.checkPromotionCode && res.data.checkPromotionCode.active) {
        this.promotionCode_error = null;
        this.couponCodeId = res.data.checkPromotionCode.id;
     
        if((res.data.checkPromotionCode.max_redemptions > res.data.checkPromotionCode.times_redeemed) || (res.data.checkPromotionCode.max_redemptions == null && res.data.checkPromotionCode.times_redeemed == null)) {
        
          this.$gtag.event('pmb_app_final_checkout_check_promo_code_success');
          if(res.data.checkPromotionCode.coupon.amount_off) {
            this.discountAmtOrPercent = '$'+res.data.checkPromotionCode.coupon.amount_off;
            this.discountAmt = parseFloat(res.data.checkPromotionCode.coupon.amount_off.toFixed(1));
          }else{
            this.discountAmtOrPercent = res.data.checkPromotionCode.coupon.percent_off + '%';
            this.discountAmt = parseFloat(((res.data.checkPromotionCode.coupon.percent_off / 100) * this.subscriptionPrice).toFixed(1));
          }

          if(this.subscriptionPrice > this.discountAmt) {
            //this.netAmount = this.subscriptionPrice - this.discountAmt;
            this.appliedCoupon = true;
          }else{

            this.netAmount = this.subscriptionPrice;
            this.appliedCoupon = false;
            this.couponData = null;
            this.$gtag.event('pmb_app_final_checkout_check_promo_code_failed', {error: this.promotionCode_error});
            this.promotionCode_error = 'This coupon does not exist'
            this.discountAmtOrPercent = null;
            this.discountAmt = 0;
          }

        }

      }else{
        this.appliedCoupon = false;
        this.promotionCode_error = 'No valid promotion code found!'
        this.$gtag.event('pmb_app_final_checkout_check_promo_code_failed', {error: this.promotionCode_error});
        this.discountAmtOrPercent = null;
        this.discountAmt = 0;
        this.couponData = null;
      }
    })
  }

  cancelPromotionCode() {
    this.appliedCoupon = false;
    this.promotionCode_error = null;
    this.discountAmtOrPercent = null;
    this.discountAmt = 0;
    this.couponData = null;
    //this.netAmount = this.subscriptionPrice - this.discountAmt;
    this.appliedCoupon = false;
    this.promotionCode = null;
  }

  openBillingDetailsEdit() {
    this.$buefy.modal.open({
      component: EditBillingDetails,
      props: {
        open: true
      },
      width: '480px',
      parent: this,
    });
  }


  @Watch('selectedInterval')
  changePlanInterval(_newPlan: any) {
   
    if(this.couponData && this.couponData?.active) {
      if((this.couponData.max_redemptions! > this.couponData?.times_redeemed!) || (this.couponData?.max_redemptions == null && this.couponData?.times_redeemed == null)) {
        if(this.couponData?.coupon) {
          if(this.couponData?.coupon.amount_off) {
            this.discountAmtOrPercent = '$'+this.couponData?.coupon.amount_off;
            this.discountAmt = parseFloat(this.couponData?.coupon.amount_off.toFixed(1));
          }else{
            this.discountAmtOrPercent = this.couponData?.coupon.percent_off + '%';
            this.discountAmt = parseFloat(((this.couponData?.coupon.percent_off! / 100) * this.subscriptionPrice).toFixed(1));
          }
          //this.netAmount = this.subscriptionPrice - this.discountAmt;
          this.appliedCoupon = true;
        }

      }
    }
  }


}
